import './mobile-header-menu.css';
import logoImgSrc from './static/logo.png';
import { Rotate as Hamburger } from 'hamburger-react'
import { Image, Menu, MenuItem, MenuMenu, Sidebar } from "semantic-ui-react";
import { useState } from "react";

const MobileHeaderMenu = ({activeItem, handleItemClick}) => {
    const [open, setOpen] = useState(false)

    const mobileHandleItemClick = (to) => {
        setOpen(false);
        handleItemClick(to)
    }

    return (
        <>
            <Menu secondary size='small' fixed='top' className="mobile-header-menu">
                <MenuItem header onClick={() => mobileHandleItemClick('/')}>
                    <Image alt='logo' src={logoImgSrc} className="mobile-header-logo"/>
                </MenuItem>
                <MenuMenu position='right'>
                    <MenuItem>
                        <Hamburger toggled={open} toggle={setOpen} size={35} distance='sm'/>
                    </MenuItem>
                </MenuMenu>
            </Menu>
            <Sidebar as={Menu}
                     className='mobile-header-menu-sidebar'
                     vertical
                     visible={open}
            >
                <MenuItem
                    name='home'
                    active={activeItem === '/'}
                    onClick={() => mobileHandleItemClick('/')}
                    className='mobile-header-menu-item'
                >
                    <span>Home</span>
                </MenuItem>
                <MenuItem
                    name='projects'
                    active={activeItem === '/projects'}
                    onClick={() => mobileHandleItemClick('/projects')}
                    className='mobile-header-menu-item'
                >
                    <span>Projects</span>
                </MenuItem>
                <MenuItem
                    name='about'
                    active={activeItem === '/about'}
                    onClick={() => mobileHandleItemClick('/about')}
                    className='mobile-header-menu-item'
                >
                    <span>About</span>
                </MenuItem>
                <MenuItem
                    name='contact'
                    active={activeItem === '/contact'}
                    onClick={() => mobileHandleItemClick('/contact')}
                    className='mobile-header-menu-item'
                >
                    <span>Contact</span>
                </MenuItem>
            </Sidebar>
        </>

    )
};

export default MobileHeaderMenu;