import './desktop-header-menu.css';
import logoImgSrc from './static/logo.png';
import { Container, Image, Menu, MenuItem, MenuMenu } from "semantic-ui-react";

const DesktopHeaderMenu = ({activeItem, handleItemClick}) => {
    return (
        <Menu pointing secondary size='massive' fixed='top' className='header-menu'>
            <Container>
                <MenuItem
                    header
                    fitted
                    onClick={() => handleItemClick('/')}
                    className='header-menu-logo'
                >
                    <Image alt='logo' src={logoImgSrc} size='small'/>
                </MenuItem>
                <MenuMenu position='right'>
                    <MenuItem
                        name='home'
                        active={activeItem === '/'}
                        onClick={() => handleItemClick('/')}
                        className='header-menu-item'
                    >
                        Home
                    </MenuItem>
                    <MenuItem
                        name='projects'
                        active={activeItem === '/projects'}
                        onClick={() => handleItemClick('/projects')}
                        className='header-menu-item'
                    >
                        Projects
                    </MenuItem>
                    <MenuItem
                        name='about'
                        active={activeItem === '/about'}
                        onClick={() => handleItemClick('/about')}
                        className='header-menu-item'
                    >
                        About
                    </MenuItem>
                    <MenuItem
                        name='contact'
                        active={activeItem === '/contact'}
                        onClick={() => handleItemClick('/contact')}
                        className='header-menu-item'
                    >
                        Contact
                    </MenuItem>
                </MenuMenu>
            </Container>
        </Menu>
    )
};

export default DesktopHeaderMenu;