const SET_MENU_ITEM = 'SET_MENU_ITEM';
const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';

export const initialState = {
    activeMenuItem: '/',
    activeProject: 0
};

export const setActiveMenuItem = (activeMenuItem) => ({
    type: SET_MENU_ITEM,
    activeMenuItem
});

export const setActiveProject = (activeProject) => ({
    type: SET_ACTIVE_PROJECT,
    activeProject
});

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MENU_ITEM:
            return {
                ...state,
                activeMenuItem: action.activeMenuItem
            };
        case SET_ACTIVE_PROJECT:
            return {
                ...state,
                activeProject: action.activeProject
            }
        default:
            return state;
    }
};