import './contact.css';
import { Button, Container, Form, FormGroup, Grid, Header, Input, Message, TextArea } from "semantic-ui-react";
import { useEffect, useRef, useState } from "react";
import GoogleCaptchaV2 from "./contact-form-captcha";

const ContactForm = () => {

    const [emailFieldError, setEmailFieldError] = useState(false);
    const [firstNameFieldError, setFirstNameFieldError] = useState(false);
    const [lastNameFieldError, setLastNameFieldError] = useState(false);
    const [subjectFieldError, setSubjectFieldError] = useState(false);
    const [textFieldError, setTextFieldError] = useState(false);
    const [emailValue, setEmailValue] = useState("");
    const [firstNameValue, setFirstNameValue] = useState("");
    const [lastNameValue, setLastNameValue] = useState("");
    const [subjectValue, setSubjectValue] = useState("");
    const [textValue, setTextValue] = useState("");
    const [canSubmit, setCanSubmit] = useState(false);
    const [successMessageHidden, setSuccessMessageHidden] = useState(true);
    const [failureMessageHidden, setFailureMessageHidden] = useState(true);
    const [isCaptchaInvalid, setIsCaptchaInvalid] = useState(true);
    const captchaRef = useRef(null);

    const valueFieldSetters = {
        firstName: setFirstNameValue,
        lastName: setLastNameValue,
        email: setEmailValue,
        subject: setSubjectValue,
        text: setTextValue
    }

    const setValue = (id, value) => {
        valueFieldSetters[id](value);
        setSuccessMessageHidden(true);
        setFailureMessageHidden(true);
    }

    const validateFirstName = () => {
        if (firstNameValue && firstNameValue.length < 1){
            setFirstNameFieldError({
                content: "First name is mandatory",
                pointing: 'above'
            });
            setCanSubmit(false);
        } else {
            setFirstNameFieldError(false);
            setCanSubmit(true);
        }
    }

    const validateLastName = () => {
        if (lastNameValue && lastNameValue.length < 1){
            setLastNameFieldError({
                content: "Last name is mandatory",
                pointing: 'above'
            });
            setCanSubmit(false);
        } else {
            setLastNameFieldError(false);
            setCanSubmit(true);
        }
    }

    const validateEmail = () => {
        const pattern = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
        if (emailValue && !pattern.test(emailValue)){
            setEmailFieldError({
                content: "Invalid email",
                pointing: 'above'
            });
            setCanSubmit(false);
        } else {
            setEmailFieldError(false);
            setCanSubmit(true);
        }
    }

    const validateSubject = () => {
        if (subjectValue && subjectValue.length < 1){
            setSubjectFieldError({
                content: "Subject is mandatory",
                pointing: 'above'
            });
            setCanSubmit(false);
        } else {
            setSubjectFieldError(false);
            setCanSubmit(true);
        }
    }

    const validateText = () => {
        if (textValue && textValue.length < 1){
            setTextFieldError({
                content: "Invalid message! Minimum 10 characters.",
                pointing: 'above'
            });
            setCanSubmit(false);
        } else {
            setTextFieldError(false);
            setCanSubmit(true);
        }
    }

    useEffect(() => {
        validateFirstName();
    }, [firstNameValue]);
    useEffect(() => {
        validateLastName();
    }, [lastNameValue]);
    useEffect(() => {
        validateEmail();
    }, [emailValue]);
    useEffect(() => {
        validateSubject();
    }, [subjectValue]);
    useEffect(() => {
        validateText();
    }, [textValue]);

    const onSubmit = (event) => {
        event.preventDefault();

        if (canSubmit
            && firstNameValue && lastNameValue && emailValue && subjectValue && textValue
            && captchaRef?.current?.getValue()) {
            const token = captchaRef.current.getValue();
            submit(token);

            setFirstNameValue("");
            setLastNameValue("")
            setEmailValue("");
            setSubjectValue("");
            setTextValue("");
        }
    }

    const submit = (token) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                firstName: firstNameValue,
                lastName: lastNameValue,
                email: emailValue,
                subject: subjectValue,
                text: textValue,
                token: token
            })
        };
        fetch("https://uzgp631x5c.execute-api.eu-west-2.amazonaws.com/v1/contact-us", requestOptions)
            .then(response => {
                console.log(response.json());
                if (response.status === 200) {
                    setSuccessMessageHidden(false);
                } else {
                    setFailureMessageHidden(false);
                }
            })
            .catch(err => {
                console.error(err);
                setFailureMessageHidden(false);
            });
    }

    return (
        <Form className='contact-form'
              onSubmit={onSubmit}
              noValidate
              success
        >
            <FormGroup widths='equal'>
                <Form.Field
                    id='firstName'
                    required
                    control={Input}
                    label="First name"
                    error={firstNameFieldError}
                    value={firstNameValue}
                    onChange={event => setValue(event.target.id, event.target.value)}
                />
                <Form.Field
                    id='lastName'
                    required
                    control={Input}
                    label="Last name"
                    error={lastNameFieldError}
                    value={lastNameValue}
                    onChange={event => setValue(event.target.id, event.target.value)}
                />
            </FormGroup>
            <Form.Field
                id='email'
                required
                control={Input}
                label="Email"
                error={emailFieldError}
                value={emailValue}
                onChange={event => setValue(event.target.id, event.target.value)}
            />
            <Form.Field
                id='subject'
                control={Input}
                label="Subject"
                error={subjectFieldError}
                value={subjectValue}
                onChange={event => setValue(event.target.id, event.target.value)}
            />
            <Form.Field
                id='text'
                required
                control={TextArea}
                label="Message"
                error={textFieldError}
                value={textValue}
                onChange={event => setValue(event.target.id, event.target.value)}
            />
            <GoogleCaptchaV2
                captchaRef={captchaRef}
                isCaptchaInvalid={isCaptchaInvalid}
                setIsCaptchaInvalid={setIsCaptchaInvalid}/>
            <Button primary size='massive' className="contact-form-button" disabled={isCaptchaInvalid}>
                Submit
            </Button>
            <Message
                hidden={successMessageHidden}
                success
                header="Request sent"
                content="We will contact you soon"
            />
            <Message
                hidden={failureMessageHidden}
                negative
                header="Failed to send the request"
                content="Please try again later or contact us on listed email or phone numbers"
            />
        </Form>
    );
};

const Contact = () => {
    return (
        <Container className="contact">
            <Grid verticalAlign='middle' doubling columns={2}>
                <Grid.Column>
                    <Header
                        as='h3'
                        content='Let’s Work Together'
                        className='contact-h3'
                    />
                    <p className="contact-description">
                        We’re always looking for new opportunities and are comfortable working internationally.
                        Please get in touch and one of our project managers will contact you about beginning the
                        proposal process.
                    </p>
                    <hr className="contact-details-hr"/>
                    <div className="contact-details-block">
                        <p className="contact-details">
                            +44 7710 579111
                        </p>
                        <p className="contact-details">
                            +44 7850 048527
                        </p>
                        <p className="contact-details">
                            info@visgroup.co.uk
                        </p>
                    </div>
                </Grid.Column>
                <Grid.Column>
                    <ContactForm/>
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default Contact;