import { useLocation, useNavigate } from "react-router-dom";
import DesktopHeaderMenu from "./desktop-header-menu";
import MobileHeaderMenu from "./mobile-header-menu";
import { useBigScreen } from "../../hooks/useBigScreen";
import { useStore } from "../../store/store";
import { setActiveMenuItem, setActiveProject } from "../../store/reducer";
import { useEffect } from "react";

const HeaderMenu = () => {
    const location = useLocation();
    const [{ activeMenuItem }] = useStore();

    const navigate = useNavigate();
    const [, dispatch] = useStore();

    const handleItemClick = (to) => {
        dispatch(setActiveMenuItem(to));
        dispatch(setActiveProject(0));
        navigate(to);
    }

    const isBigScreen = useBigScreen();

    useEffect(() => {
        dispatch(setActiveMenuItem(location.pathname));
    }, []);

    return (
        <>
            {isBigScreen && <DesktopHeaderMenu activeItem={activeMenuItem} handleItemClick={handleItemClick}/>}
            {!isBigScreen && <MobileHeaderMenu activeItem={activeMenuItem} handleItemClick={handleItemClick}/>}
        </>
    )
};

export default HeaderMenu;