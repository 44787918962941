import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "../../screens/home";
import About from "../../screens/about";
import Footer from "../footer";
import ScrollToTop from "../scroll-to-top";
import Contact from "../../screens/contact";
import Projects from "../../screens/projects";
import HeaderMenu from "../header-menu";

const App = () => {
    return (
        <>
            <Router>
                <ScrollToTop/>
                <HeaderMenu/>
                {/*<HeaderMenu/>*/}
                <div style={{
                    minHeight: 'calc(100vh - 262px - 120px)', /* minus footer height */
                    paddingTop: '120px' /* header height */
                }}>
                    <Routes>
                        <Route path='/' element={<Home/>}/>
                    </Routes>
                    <Routes>
                        <Route path='/projects' element={<Projects/>}/>
                    </Routes>
                    <Routes>
                        <Route path='/about' element={<About/>}/>
                    </Routes>
                    <Routes>
                        <Route path='/contact' element={<Contact/>}/>
                    </Routes>
                </div>
                <Footer/>
            </Router>
        </>
    )
};

export default App;