import 'semantic-ui-css/semantic.min.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from "./components/app";
import { StoreProvider } from "./store/store";
import { initialState, reducer } from "./store/reducer";

ReactDOM.render(
    <React.StrictMode>
        <StoreProvider initialState={initialState} reducer={reducer}>
            <App/>
        </StoreProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
