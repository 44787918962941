import './home.css';
import homeCover from './static/vis-cover.png';
import project1 from './static/project1.png';
import project2 from './static/project2.png';
import project3 from './static/project3.png';
import project4 from './static/project4.png';
import { Button, Container, Grid, Header, Image, Segment } from "semantic-ui-react";
import { useBigScreen } from "../../hooks/useBigScreen";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/store";
import { setActiveMenuItem, setActiveProject } from "../../store/reducer";

const HomeHeading = () => {

    return (
        <Container className='home-header'>
            <Header
                as='h1'
                content='Breathing innovation into every space.'
                className='home-header-h1'
            />
            <Header
                as='h2'
                content='HVAC Specialists. Installation & Contracting.'
                className='home-header-h2'
            />
        </Container>
    )
}

const HomeWorkDescription = () => {

    return (
        <Container className='home-work'>
            <Header
                as='h3'
                content='Recent Work'
                className='home-work-h3'
            />
            <Grid doubling columns={2}>
                <Grid.Column>
                    <p className="home-work-description">
                        HVAC Specialists providing a wide range of Ventilation and Air conditioning services throughout
                        the UK and Europe.
                    </p>
                    <p className="home-work-description">
                        VIS offer standard and bespoke installations throughout all levels of
                        installation, working closely with our client’s needs and detailed specifications.
                    </p>
                    <p className="home-work-description">
                        Highest level of quality and service on time and snag-free.
                    </p>
                </Grid.Column>
            </Grid>
        </Container>
    )
}

const HomeProjects = () => {
    const isBigScreen = useBigScreen();
    const navigate = useNavigate();

    const [, dispatch] = useStore();

    const handleClick = (projectIndex) => {
        dispatch(setActiveProject(projectIndex));
        dispatch(setActiveMenuItem("/projects"));
        navigate("/projects");
    }

    const projects = [
        { projectImgSrc: project1, projectName: "Project One" },
        { projectImgSrc: project2, projectName: "Project Two" },
        { projectImgSrc: project3, projectName: "Project Three" },
        { projectImgSrc: project4, projectName: "Project Four" }
    ];

    return (
        <Container fluid className={isBigScreen ? "home-projects desktop" : "home-projects"}>
            <Grid doubling columns={2}>
                {projects.map(({projectImgSrc, projectName}, key) =>
                    <Grid.Column
                        key={key}
                        className="home-project-item"
                        onClick={() => handleClick(key)}
                    >
                        <Image src={projectImgSrc} centered className="home-project-image"/>
                        <Header
                            as='h4'
                            content={projectName}
                            className='home-project-h4'
                        />
                    </Grid.Column>
                )}
            </Grid>
        </Container>
    )
}

const HomeTestimonials = () => {
    const isBigScreen = useBigScreen();

    const testimonials = [
        {
            content: "Working with VIS GROUP was a game-changer for our facility. Their ventilation solutions not only " +
                "optimized our airflow but also transformed our working environment. Their commitment to quality and " +
                "attention to detail were unmatched. Highly recommended for anyone seeking top-tier ventilation " +
                "expertise!",
            author: "Sarah T., Facility Manager"
        },
        {
            content: "VIS GROUP exceeded our expectations with their innovative approach to ventilation systems. " +
                "Their team's professionalism and dedication to finding tailored solutions for our complex needs were " +
                "truly impressive. The results spoke for themselves—improved air quality and efficiency across our " +
                "manufacturing plant. A fantastic partner!",
            author: "Mark P., Operations Director"
        },
        {
            content: "Choosing VIS GROUP was one of the best decisions we made for our commercial space. " +
                "Their expertise in designing and implementing ventilation systems elevated our workspace comfort and " +
                "productivity. Their team's passion for delivering excellence was evident throughout the project. " +
                "We couldn't be happier!",
            author: "Emily R., Business Owner"
        }
    ];

    return (
        <Container fluid className={isBigScreen ? "home-testimonials desktop" : "home-testimonials"}>
            <Header
                as='h3'
                content='Testimonials'
                className='home-testimonials-h3'
            />
            <Grid doubling stackable columns={3}>
                {testimonials.map(({content, author}, key) =>
                    <Grid.Column key={key}>
                        <p className="home-testimonials-item-content">
                            {content}
                        </p>
                        <p className="home-testimonials-item-author">
                            {author}
                        </p>
                    </Grid.Column>
                )}

            </Grid>
        </Container>
    )
}

const HomeContact = () => {

    const navigate = useNavigate();

    const [, dispatch] = useStore();

    const handleButtonClick = (event) => {
        event.preventDefault();
        dispatch(setActiveMenuItem('/contact'));
        navigate('/contact');
    }

    return (
        <Segment inverted basic>
            <Container className='home-contact'>
                <Header
                    as='h3'
                    content='Let’s Work Together'
                    className='home-contact-h3'
                />
                <Grid doubling columns={2}>
                    <Grid.Column >
                        <p className="home-contact-description">
                            We’re always looking for new opportunities and are comfortable working internationally.
                            Please get in touch and one of our project managers will contact you about beginning the
                            proposal process.
                        </p>
                        <Button primary size='massive' className="home-contact-button" onClick={handleButtonClick}>
                            Contact Us
                        </Button>
                    </Grid.Column>
                </Grid>
            </Container>
        </Segment>
    )
}

const Home = () => {
    const isBigScreen = useBigScreen();

    return (
        <>
            <HomeHeading/>
            <Image src={homeCover} centered className={isBigScreen ? "home-cover desktop" : "home-cover"}/>
            <HomeWorkDescription/>
            <HomeProjects/>
            <HomeTestimonials/>
            <HomeContact/>
        </>
    );
};

export default Home;