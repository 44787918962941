import './projects.css';
import project11 from './static/project1_1.png';
import project12 from './static/project1_2.png';
import project13 from './static/project1_3.png';
import project14 from './static/project1_4.png';
import project21 from './static/project2_1.png';
import project22 from './static/project2_2.png';
import project23 from './static/project2_3.png';
import project24 from './static/project2_4.png';
import project31 from './static/project3_1.png';
import project32 from './static/project3_2.png';
import project33 from './static/project3_3.png';
import project34 from './static/project3_4.png';
import project41 from './static/project4_1.png';
import project42 from './static/project4_2.png';
import project43 from './static/project4_3.png';
import project44 from './static/project4_4.png';
import { Container, Header, Icon, Image } from "semantic-ui-react";
import { useEffect, useRef } from "react";
import { register } from 'swiper/element/bundle';
import { useStore } from "../../store/store";
import { setActiveProject } from "../../store/reducer";

// https://swiperjs.com/swiper-api
// https://www.freecodecamp.org/news/how-to-set-up-swiper-element-in-a-react-application/#responsivebreakpointsinswiperelement
// https://swiperjs.com/blog/using-swiper-element-in-react
const Swiper = (props) => {
    const swiperRef = useRef(null);
    const {
        children,
        ...rest
    } = props;

    useEffect(() => {
        // Register Swiper web component
        register();

        // pass component props to parameters
        const params = {
            ...rest
        };

        // Assign it to swiper element
        Object.assign(swiperRef.current, params);

        // initialize swiper
        swiperRef.current.initialize();
    }, []);

    return (
        <swiper-container init="false" ref={swiperRef} style={{
            "--swiper-pagination-color": "#fff",
            "--swiper-navigation-color": "#fff"
        }}>
            {children}
        </swiper-container>
    );
}
const SwiperSlide = (props) => {
    const {
        children,
        ...rest
    } = props;

    return (
        <swiper-slide {...rest}>
            {children}
        </swiper-slide>
    );
}

const Projects = () => {
    const [{ activeProject }] = useStore();

    const [, dispatch] = useStore();

    const handleClick = (to) => {
        dispatch(setActiveProject(to));
    }

    const projects = [
        {
            name: "Project One",
            images: [project11, project12, project13, project14]
        },
        {
            name: "Project Two",
            images: [project21, project22, project23, project24]
        },
        {
            name: "Project Three",
            images: [project31, project32, project33, project34]
        },
        {
            name: "Project Four",
            images: [project41, project42, project43, project44]
        }
    ]

    return (
        <Container fluid className="project">
            <Header
                textAlign='center'
                as='h1'
                content={projects[activeProject].name}
                className='project-name'
            />
            <Swiper
                    slidesPerView={1}
                    breakpoints={{ 992: { slidesPerView: 3, spaceBetween: 40 } }}
                    navigation={true}
                    pagination={true}
                    centeredSlides={true}
                    loop={true}
            >
                {projects[activeProject].images.map((imageSrc, slideKey) =>
                    <SwiperSlide key={slideKey}>
                        <Image src={imageSrc} centered/>
                    </SwiperSlide>
                )}
            </Swiper>
            {activeProject > 0 &&
                <Header
                    floated="left"
                    as='h2'
                    className='project-nav'
                    onClick={() => handleClick(activeProject-1)}
                >
                    <Icon name='caret left'/>
                    {projects[activeProject-1].name}
                </Header>
            }

            {activeProject < projects.length-1 &&
                <Header
                    floated="right"
                    as='h2'
                    className='project-nav'
                    onClick={() => handleClick(activeProject+1)}
                >
                    {projects[activeProject+1].name}
                    <Icon name='caret right'/>
                </Header>
            }

        </Container>
    );
};

export default Projects;