import './footer.css';
import { Container, Grid, Header } from "semantic-ui-react";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <Container className='footer'>
            <Header
                as='h1'
                content='VIS GROUP'
                className='footer-h1'
            />
            <Header
                as='h2'
                content='HVAC Specialists'
                className='footer-h2'
            />
            <Header
                as='h3'
                content='Installation & Contracting'
                className='footer-h3'
            />
            <Grid doubling columns={2}>
                <Grid.Column>
                    <p className="footer-details">
                        +44 7710 579111
                    </p>
                    <p className="footer-details">
                        +44 7850 048527
                    </p>
                    <p className="footer-details">
                        info@visgroup.co.uk
                    </p>
                    <p className="footer-copy-rights">
                        © {currentYear} Powered by<span> </span>
                        <a href="https://maximdomentii.com" target="_blank" className="powered-by">
                            maximdomentii.com
                        </a>
                    </p>
                </Grid.Column>
            </Grid>
        </Container>
    )
};

export default Footer;