import './about.css';
import { Button, Container, Grid, Header, Segment } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/store";
import { setActiveMenuItem } from "../../store/reducer";

const About = () => {
    const navigate = useNavigate();

    const [, dispatch] = useStore();

    const handleButtonClick = (event) => {
        event.preventDefault();
        dispatch(setActiveMenuItem('/contact'));
        navigate('/contact');
    }

    return (
        <>
            <Container className="about">
                <Grid doubling columns={2}>
                    <Grid.Column width={6}>
                        <Header
                            as='h3'
                            content='About'
                            className='about-h3'
                        />
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <p className="about-description">
                            At VIS GROUP, we stand as pioneers in revolutionizing air quality and ventilation solutions.
                            With a relentless pursuit of innovation and a steadfast commitment to excellence, we've
                            established ourselves as leaders in the industry. Our journey began with a vision:
                            to transform spaces by optimizing airflow and creating environments that promote well-being.
                            From that vision, we've grown into a trusted partner, delivering cutting-edge ventilation
                            systems that redefine industry standards.
                        </p>
                        <p className="about-description">
                            Our mission is simple yet profound: to engineer air quality solutions that go beyond
                            functionality. We strive to create environments where people thrive—spaces that are not
                            just well-ventilated but also foster productivity, health, and comfort. Every project we
                            undertake is a testament to our dedication to innovation, precision, and the relentless
                            pursuit of excellence. We're driven by the belief that superior air quality isn't just a luxury;
                            it's a fundamental right that impacts every aspect of life.
                        </p>
                        <p className="about-description">
                            What sets us apart is our unwavering commitment to tailor-made solutions. We understand that
                            each space is unique, and our approach reflects this understanding. With a team of experts who
                            possess a wealth of experience and a passion for pushing the boundaries of possibility,
                            we approach every project with creativity and meticulous attention to detail. Our ability to
                            blend state-of-the-art technology with personalized strategies ensures that our solutions not
                            only meet but exceed expectations.
                        </p>
                        <p className="about-description">
                            When you choose VIS GROUP, you're not just selecting a ventilation system provider—you're
                            choosing a partner dedicated to your success. We promise transparency, reliability,
                            and a collaborative spirit throughout our journey together. Our commitment doesn't end with the
                            completion of a project; we're here to provide ongoing support and maintenance, ensuring that
                            your environment continues to meet the highest standards long into the future.
                        </p>
                    </Grid.Column>
                </Grid>
            </Container>
            <Segment basic className="about-contact">
                <Container>
                    <Header
                        as='h3'
                        content='Let’s Work Together'
                        className='about-contact-h3'
                    />
                    <Button primary size='massive' className="about-contact-button" onClick={handleButtonClick}>
                        Contact Us
                    </Button>
                </Container>
            </Segment>
        </>
    );
};

export default About;